import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Login from './components/loginPage/Login'; // Importing the Login component
import ThumbnailGroup from "./components/ThumbnailGroup.tsx";
import './App.css'; // Import the CSS file

const DarkBackground = styled.div`
  background-color: #f5f5f4; /* Dark grey background */
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const App = () => {
    const [user, setUser] = useState(null);
    const [cookieChecked, setCookieChecked] = useState(false); // State to track whether the initial cookie check is completed

    useEffect(() => {
        // On component mount and page refresh, check if there's a user stored in the cookie
        const storedUser = getCookie('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser)); // Parse the stored user string to object
        }
        setCookieChecked(true); // Mark initial cookie check as completed

        // Define timeout duration (in milliseconds)
        const TIMEOUT_DURATION = 60 * 60 * 1000; // 1 hour

        // Set up event listeners to track user activity
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            startTimeout();
        };

        const startTimeout = () => {
            const timeoutId = setTimeout(() => {
                handleLogout();
            }, TIMEOUT_DURATION);
            return timeoutId;
        };

        const handleUserActivity = () => {
            resetTimeout();
        };

        // Event listeners for mouse movement and keyboard input
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keypress', handleUserActivity);

        // Start timeout when component mounts
        const timeoutId = startTimeout();

        // Clean up event listeners and timeout when component unmounts or user logs out
        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keypress', handleUserActivity);
            clearTimeout(timeoutId);
        };
    }, []);

    const handleLogin = (loggedInUser) => {
        setUser(loggedInUser);
        // Store the user in a cookie
        setCookie('user', JSON.stringify(loggedInUser), 2 / 24); // Set expiration to approximately 2 hours
    };

    const handleLogout = () => {
        setUser(null);
        // Erase the user cookie
        eraseCookie('user');
    };

    return (
        <>
            {cookieChecked && ( // Only render Login or MainPage components if the initial cookie check is completed
                user ? (
                    <ThumbnailGroup user={user} handleLogout={handleLogout} />
            ) : (
                    <DarkBackground>
                        <Login onLogin={handleLogin} />
                    </DarkBackground>
                )
            )}
         </>
    );
};

// Cookie functions
const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
};

const eraseCookie = (name) => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
};

export default App;
