import React, { useState } from 'react';
import styled from 'styled-components';
import { signInUserWithEmailAndPassword, createUserWithEmailAndPass } from '../db/firebase';

const Container = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; /* Light grey background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 300px; /* Adjust the width as needed */
  width: 100%; /* Ensure the form takes up the available width */
`;

const Title = styled.h2`
  text-align: center;
  color: #333; /* Dark text */
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  color: #333; /* Dark text */
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #aaa; /* Grey border */
  border-radius: 3px;
  background-color: #f4f4f4; /* Light grey input background */
  color: #333; /* Dark text */
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff; /* Blue button */
  color: #fff; /* White text */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px; 
`;

const LogoImage = styled.img`
  max-width: 40%; /* Ensure the image doesn't exceed its container */
  max-height: 40%; /* Adjust the maximum height as needed */
  display: block;
  margin: 0 auto 20px; /* Center the image and provide some bottom margin */
`;

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            const user = await signInUserWithEmailAndPassword(username, password);
            onLogin(user);
        } catch (error) {
            console.error('Login Error:', error);
        }
    };

    const handleSignUp = async () => {
        try {
            const user = await createUserWithEmailAndPass(username, password);
            onLogin(user);
        } catch (error) {
            console.error('Sign Up Error:', error);
        }
    };

    return (
        <Container>
            <LogoImage src="https://www.designconsulting.com.au/wp-content/uploads/2021/02/cropped-Design-Consulting-Logo-Black-Red-Circle-bg-192x192.png" alt="logo" />
            <Title>Login</Title>
            <Form>
                <Label>Username:</Label>
                <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                <Label>Password:</Label>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Button type="submit" onClick={e => {
                    e.preventDefault(); // Prevent default form submission behavior
                    handleLogin(); // Call handleLogin function
                }}>Login</Button>            </Form>
        </Container>
    );
};

export default Login;
