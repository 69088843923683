import { initializeApp } from 'firebase/app';
import { getAuth, updatePassword , signInWithEmailAndPassword, createUserWithEmailAndPassword, deleteUser as deleteAuthUser } from 'firebase/auth';
import { collection, where, addDoc, updateDoc, setDoc, getFirestore, getDocs, getDoc, query, doc, deleteDoc } from 'firebase/firestore';
import { getUserByEmail } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCeM-ErX5wNhNLsfuegVoNeGGOqeqPm_-s",
    authDomain: "timex-queueing-server.firebaseapp.com",
    projectId: "timex-queueing-server",
    storageBucket: "timex-queueing-server.appspot.com",
    messagingSenderId: "129502529991",
    appId: "1:129502529991:web:623bf090acd610befe71e2",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const createUserWithEmailAndPass = async (username, password, userType) => {
    try {
        const email = `${username}@queueingserverdesignconsultingapp.com`;
        console.log('Creating user...');

        // Create user in Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if user data is retrieved
        if (!user) {
            throw new Error('Failed to create user');
        }

        console.log('User created:', user.uid);

        // Add user details to Firestore database
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, {
            email: email,
            type: userType
        });

        console.log('User details added to database:', user.uid);

        alert('Sign up successful');
        return user;
    } catch (error) {
        console.error('Error during user creation:', error);
        alert('Sign up error');
        throw error;
    }
};
const signInUserWithEmailAndPassword = async (username, password) => {
    try {
        const email = `${username}@queueingserverdesignconsultingapp.com`;
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return user;
    } catch (error) {
        alert('Incorrect Details');
        throw error; // Re-throw the error to handle it in the component
    }
};

const getAllUsersWithTypes = async () => {
    try {
        const usersCollection = collection(db, 'users'); // 'users' is the name of your Firestore collection
        const q = query(usersCollection); // Query all documents in the 'users' collection
        const querySnapshot = await getDocs(q);

        const users = [];
        querySnapshot.forEach((doc) => {
            // Extract user data and type from each document
            const userData = doc.data();
            const user = {
                id: doc.id,
                email: userData.email,
                type: userData.type, // Assuming 'type' is the field storing user type
            };
            users.push(user);
        });

        return users;
    } catch (error) {
        throw error;
    }
};

const getUserByUid = async (uid) => {
    try {
        const usersCollection = collection(db, 'users'); // 'users' is the name of your Firestore collection
        const docRef = doc(usersCollection, uid); // 'uid' is the document ID
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
            throw new Error(`User with UID ${uid} not found`);
        }

        const userData = docSnapshot.data();

        const user = {
            id: docSnapshot.id,
            email: userData.email,
            type: userData.type, // Assuming 'type' is the field storing user type
        };

        return user;
    } catch (error) {
        throw error;
    }
};


const deleteUser = async (userId) => {
    try {
        // Delete user from Firebase Authentication using user ID
        await deleteAuthUser(auth.currentUser);

        // Get the reference to the user document using the user ID
        const userDocRef = doc(db, 'users', userId);

        // Delete user document from Firestore database
        await deleteDoc(userDocRef);

        alert('User deleted successfully');
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};

async function updateUserRole(userId, newRole) {
    try {
        // Get the reference to the user document using the user ID
        const userDocRef = doc(db, 'users', userId);

        // Update the user's role
        await updateDoc(userDocRef, { type: newRole });

        console.log('User role updated successfully.');
        return { success: true };
    } catch (error) {
        console.error('Error updating user role:', error);
        throw new Error('An error occurred while updating user role');
    }
}

const setUserPassword = async (userId, newPassword) => {
    try {
        // Update password in Firebase Authentication
        await updatePassword('deleteTest@queueingserverdesignconsultingapp.com', newPassword);

        console.log('Password updated successfully');

        alert('Password updated successfully');
    } catch (error) {
        console.error('Error updating password:', error);
        alert('Password update error');
        throw error;
    }
};


export { setUserPassword, getUserByUid, updateUserRole, signInUserWithEmailAndPassword, createUserWithEmailAndPass, getAllUsersWithTypes, deleteUser };
