import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4cm,1fr));
    gap: 30px;
    row-gap:20px;

`
const ParentContainer = styled.div`
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 100px;
    background-color: #f5f5f4;

`
const ImageContainer = styled.div`
    width:4.1cm;
    text-align:center;
    border-radius: 3px;
    margin: 2px;
    padding:10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
     }
`

const ImageContainerSelected = styled.div`
    width:4.1cm;
    text-align:center;
    border-radius: 3px;
    margin: 1px;
    padding:10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s ease-in-out;
    border: 1px solid black;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
     }
`



const ImageStandardise = styled.div`
    vertical-align: middle;
    position: relative;

    height:4cm;
    text-align: center;
    width:4cm;
    border:1px solid #f7f7f7;
    border-radius: 3px;
    padding:2px;
    background-color: #f7f7f7;

`


const Nav = styled.div`
    width:100%;
    height: 70px;
    background-color: #ffffff;
    margin-bottom:10px;
`

const Heading = styled.h1`
    margin-top:10px;
    font-family: 'Trebuchet MS', sans-serif;
    margin-left:50px;
    float: left;
    padding: 0;
    margin-bottom: 50px;
    height: 0px;


`
const ImageName = styled.h1`
    font-family: 'Trebuchet MS', sans-serif;
    word-wrap: break-word;
    font-size: 20px;
    margin:0;
    


`

const ButtonDark2 = styled.button`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    font-family: 'Trebuchet MS', sans-serif;
    
    height:30px;
    width:100px;
    margin-left:10px;
    margin-bottom: 10px;
    background-color: #f3f4ff;
    border:none;
    border-radius:3px;
`
const Button2 = styled.button`
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    font-family: 'Trebuchet MS', sans-serif;
    height:30px;
    width:100px;
    margin-left:10px;
    margin-bottom: 10px;
    background-color: white;
    border:none;
    border-radius:3px;
`
const Button = styled.button`
    font-family: 'Trebuchet MS', sans-serif;
    margin-top: 10px;
    margin-left:5px;

    background-color: white;
    border:none;
    border-radius:3px;
`
const Image = styled.img`
    max-height:100%;
    max-width:85%;

    position:absolute;
    top: 50%;
     left: 50%;
    transform: translate(-50%, -50%);

`
const Video = styled.video`
    max-height:100%;
    max-width:85%;

    position:absolute;
    top: 50%;
     left: 50%;
    transform: translate(-50%, -50%);
`
const Wrapper = styled.div`
    display:inline;
    overflow:auto;
    background: rgba(0,0,0,0.8);
    height:100%;
    width:100%;
    position:fixed;
    top: 50%;
     left: 50%;
    transform: translate(-50%, -50%);
`
const ThumbnailView = (props) => {
    let view = props.view;
    let close = props.close;
    const wrapper = (event) => {
            event.preventDefault();
            if (event.target === event.currentTarget) {
                close();
            }
        
    }
    const download = () => {
        let name = view.name;
        let path = view.path;
        fetch(path, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    const getrows = () => {
        let rows = [];
        if (view.name.split('.').pop() == 'mp4') {
            rows.push(
                <Wrapper onClick={wrapper}>

                    <Button onClick={close}>x</Button>
                    <Button onClick={download}>Download</Button>


                    <Video key="vid" controls preload="metadata">
                        <source src={view.path} type="video/mp4" />
                    </Video>
                </Wrapper>
)
        } else {
            rows.push(
                <Wrapper onClick={wrapper}>
                    <Button onClick={close}>x</Button>
                    <Button onClick={download}>Download</Button>
                    <Image key="img" src={view.path}></Image>
                </Wrapper>
            )
        }
        return rows

    }
    
    return (
        <>
         {getrows()}
       </>


        );
    
}

export default ThumbnailView;